import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout.jsx"
import SEO from "../components/seo.js"
import { Post } from "../components/post"

export default ({ data, location }) => {
  const edge = data.post.edges[0]
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={edge.node.frontmatter.title}
        description={edge.node.frontmatter.description || edge.node.excerpt}
      />
      <Post {...edge} />
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    post: allMarkdownRemark(filter: { fields: { slug: { eq: $slug } } }) {
      ...PostInformation
    }
  }
`
